import React from "react"
import headerStyles from "./headerFondue.module.css"
import { Link } from "gatsby"
import LogoBSA from "../../images/logo.png"

const HeaderFondue = React.forwardRef((prop, ref) => {
    return <div className={headerStyles.container}> 

      <Link to="/" >
          <img
              style={{ width: "250px", height: "auto" }}
              alt="logo"
              src={LogoBSA}
            />
        </Link>

        <div className={headerStyles.linkContainer}>
          <Link to="/fondue/classica" activeClassName={headerStyles.active}>classica</Link>
          <Link to="/fondue/gustosa" activeClassName={headerStyles.active}>gustosa</Link>
          <Link to="/fondue/ticinese" activeClassName={headerStyles.active}>ticinese</Link>
          <Link to="/fondue/beeehstiale" activeClassName={headerStyles.active}>beeehstiale</Link>
          <Link to="/fondue/cantoni" activeClassName={headerStyles.active}>7 cantoni</Link>
          <Link to="/fondue/alpeggi" activeClassName={headerStyles.active}>3 alpeggi</Link>
        </div>
    </div>
});

export default HeaderFondue


