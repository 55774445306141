import React from "react"
import Consigli from "../../components/fondue/consigli"
import HeaderFondue from "../../components/fondue/headerFondue"
import baseStyles from "./base.module.css"
import Footer from "../../components/footer"
import imgFondue from "../../images/low_Classica1A-Fototipo500.jpg"

export default () => {
  return <div className={baseStyles.container}>
      <HeaderFondue/>

      <div className={baseStyles.title}>MISCELA FONDUE ”CLASSICA”</div>


      <div className={baseStyles.flContainer}>

        <div className={baseStyles.flColumnPhoto}>
          <img  className={baseStyles.image}
                alt="foto confezione"
                src={imgFondue}
              />
        </div>

        <div className={baseStyles.flColumn}>
          <p style={{fontStyle:"italic"}}>preparato di formaggio da consumarsi previa cottura</p>
          <p>Gruyère DOP (formaggio grasso a pasta dura con 
          LATTE crudo svizzero, 66%), Vacherin Fribourgeois DOP
          (formaggio grasso a pasta semidura con LATTE termizzato
          svizzero, 38%), Addensante amido di mais.</p>
          <p>Prodotto in Svizzera.</p>
          <p>Confezionato in atmosfera protettiva. Conservare a +2°C - +5°C.</p>
        </div>

        <div className={baseStyles.flColumnTable}>
          <table className={baseStyles.table}>
            <thead>
              <tr>
                <th colSpan="2">Valori nutrizionali medi per 100g:</th>
              </tr>
            </thead>
            <tbody>
            <tr><td>Energia</td><td>1590 kJ / 383 kcal</td></tr>
            <tr><td>Grassi</td><td>31 g</td></tr>
            <tr><td>di cui saturi</td><td>19 g</td></tr>
            <tr><td>Carboidrati</td><td>{'<0,5 g'}</td></tr>
            <tr><td>di cui zuccheri</td><td>{'<0,5 g'}</td></tr>
            <tr><td>Proteine</td><td>25 g</td></tr>
            <tr><td>Sale</td><td>1,5 g</td></tr>
            </tbody>
          </table>
      </div>

    </div>
   
      <Consigli/>
      <Footer />
  </div>
}

