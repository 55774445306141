import React from "react"
import consigliStyles from "./consigli.module.css"
import LiquidIcon from "../../images/liquid.svg"
import DenseIcon from "../../images/dense.svg"
import SeparateIcon from "../../images/separate.svg"
import HearthIcon from "../../images/hearth.svg"

const Consigli = React.forwardRef((prop, ref) => {
    return <div className={consigliStyles.container}>
        <h2>I CONSIGLI PER UNA BUONA RIUSCITA</h2>

        <h3>SE LA TUA FONDUE È...</h3>
        <div className={consigliStyles.title}>
            <div>...troppo liquida</div><img alt="liquida" src={LiquidIcon} />
        </div>
        <p>anche se é già presente nella miscela, puoi aggiungere ancora un qualche
        grammo di maizena. Continua a mescolare formando un "8" con il mestolo e
        con un po' di pazienza vedrai che prenderà la giusta consistenza.</p>
        <div className={consigliStyles.title}>
            <div>...troppo densa</div><img alt="densa" src={DenseIcon} />
        </div>
        <p>puoi aggiungere ancora un po'di vino (mai dell'acqua). Aggiungine sempre solo
        poco per volta.</p>
        <div className={consigliStyles.title}>
            <div>...si separa</div><img alt="separa" src={SeparateIcon} />
        </div>
        <p>é possibile che stai usando una fiamma troppo alta. Abbassa la potenza della
        fiamma e lascia al formaggio il suo tempo per sciogliersi.</p>
        <div className={consigliStyles.title}>
            <div>...buonissima!!!</div><img alt="buonissima" src={HearthIcon} />
        </div>
        <p>allora hai eseguito la preparazione nel modo corretto. Ma soprattutto l'hai
        acquistata al posto giusto!</p>
        <h4>e ricorda... per un ottimo risultato ci vuole pazienza, ma soprattutto una buona
        compagnia con cui gustare in tutta tranquillità la tua fondue!</h4>
    </div>
});

export default Consigli
